import Attribute from './attribute';
import Generalization from './generalization';
import GeneralizationChild from './generalizationChild';
import Participation from './participation';
//import Relationship from './relationship';

export default class Entity {
  constructor(model, id, name, x, y, generatedFromMultivaluedAttribute, id_t) {
    this.__type = 'Entity';
    this._model = () => model;
    this._id = id;
    this._name = name;
    this._x = Math.round(x);
    this._y = Math.round(y);
    this._mag = generatedFromMultivaluedAttribute;
    this._id_t = id_t;
  }
  static fromObject(model, obj) {
    return new Entity(model, obj._id, obj._name, obj._x, obj._y, obj._mag, obj._id_t);
  }
  getId() {
    return this._id;
  }
  getIdT() {
    return this._id_t;
  }
  getName() {
    return this._name;
  }
  getX() {
    return this._x;
  }
  getY() {
    return this._y;
  }
  getAttributes() {
    if(this._model().getItemsWhere(i => i instanceof Attribute && i.getParent().getId() == this.getId()))
      return this._model().getItemsWhere(i => i instanceof Attribute && i.getParent().getId() == this.getId());
    else 
      return [];
  }
  hasAttributes() {
    return this.getAttributes().length;
  }
  getParticipations() {
    return this._model().getItemsWhere(i => i instanceof Participation && i.getEntity()?.getId() == this.getId());
  }
  hasKey() {
    return this.getAttributes().some(a => a.isIdentifier());
  }
  hasExternalIdentifier() {
    return this.getParticipations().some(p => p.isExternalIdentifier());
  }
  hasIdentifier() {
    return this.hasKey() || this.hasExternalIdentifier();
  }
  generalization() {
    return this._model().getItemWhere(i => i instanceof Generalization && i.getEntity().getId() == this.getId());
  }
  generalizationChild() {
    return this._model().getItemWhere(i => i instanceof GeneralizationChild && i.getEntity().getId() == this.getId());
  }
  getParentEntity() {
    return this.generalizationChild()?.getGeneralization().getEntity();
  }
  hasChildEntities() {
    return !!this.generalization();
  }
  wasGeneratedFromMultivaluedAttribute() {
    return this._mag;
  }
  canAddGeneralization(parent) {
    if(!(parent instanceof Entity))
      return { result: false, error: 'The selected item is not an entity.' };
    if(parent.getParentEntity())
      return { result: false, error: 'The selected entity is already child in a generalization, and cannot be at the same time parent of the current entity.' };
    return { result: true };
  }
  canBeTranslated() {
    return this.hasKey() || !this.getParticipations().filter(p => p.isExternalIdentifier()).flatMap(p => p.getRelationship().getParticipations()).some(p => p.getEntity() && p.getEntity().getId() != this.getId());
  }
  isEntity(){
    return true;
  }
  isRelationship(){
    return false;
  }
  getSupportedFunctionalities() {
    return {
      parentEntity: true,
      attribute: true,
      moving: true,
      renaming: true,
      translating: true,
    };
  }
  getAllowedFunctionalities() {
    let parentEntity = { ok: true };
   

    let attribute = { ok: true };

    return {
      attribute,
      parentEntity,

    };
  }
  getErrors() {
    let errors = [];
    
    

    return errors;
  }
  getWarnings() {
    let warnings = [];

   /* if(!this.getAttributes().some(a => !a.isIdentifier()))
      warnings.push({ scope: 'erDesign', description: `The entity has no attributes (apart from identifiers).` });*/
    
    return warnings;
  }
  __beforeDelete() {
    for(let attribute of this.getAttributes())
      this._model().deleteItem(attribute.getId());

    for(let participation of this.getParticipations())
      this._model().deleteItem(participation.getId());

    const generalization = this.generalization();
    if(generalization)
      this._model().deleteItem(generalization.getId());

    const generalizationChild = this.generalizationChild();
    if(generalizationChild)
      this._model().deleteItem(generalizationChild.getId());
  }
  setName(name) {
    name = name.replace(/\s/g, '_').replace(/[^a-zA-Z0-9_]/g, '').replace(/^\d+/g, '').toUpperCase();
    this._name = name;
    return name;
  }
  move(dx, dy) {
    this._x = Math.round(this._x + dx);
    this._y = Math.round(this._y + dy);
  }
  resetExternalIdentifierAttributes() {
    for(let attribute of this.getAttributes())
      attribute.setExternalIdentifier(false);
  }
  toERCode() {
    let code = `table ${this.getName()}`;
    if(this.hasAttributes()) {
      code += ' {\n    ';
      code += this.getAttributes().map(a => a.toERCode()).join(',\n    ');
      code += '\n}';
    }

    return code;
  }
}