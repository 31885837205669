import Participation from './participation';
//definition of generic input
export default class Relationship {
  constructor(model, id,  name, x, y, typeOfInput, id_r) {
    this.__type = 'Relationship';
    this._model = () => model;
    this._id = id;
    this._name = name;
    this._x = Math.round(x);
    this._y = Math.round(y);
    this._typeOfInput= typeOfInput;
    this._participationCount = 0;
    this._id_r = id_r;
  }
  static fromObject(model, obj) {
    return new Relationship(model, obj._id, obj._name, obj._x, obj._y, obj._typeOfInput, obj._id_r);
  }
  getId() {
    return this._id;
  }
  getIdR() {
    return this._id_r;
  }
  getName() {
    return this._name;
  }
  getX() {
    return this._x;
  }
  getY() {
    return this._y;
  }
  getTypeOfInPut() {
    return this._typeOfInput;
  }
  getTypeToDraw(){
    let draw;
    switch(this._typeOfInput){
      case 'unary': 
        draw='σ';
      break;
      case 'result': 
        draw='R';
      break;
      case 'unaryPi': 
        draw='π';
      break;
      case 'binary': 
        draw='⋈';
      break;
      case 'naturalJoin': 
        draw='⋈';
      break;
      case 'semi-join': 
        draw='⋉';
      break;
      case 'asemi-join': 
        draw='⋉';
      break;
      case 'difference': 
        draw='—';
      break;
      case 'division': 
        draw='/';
      break;
      case 'union': 
        draw='∪';
      break;
      case 'intersection': 
        draw='ᑎ';
      break;
    } 
    return draw;
  }
  getParticipationCount() {
    return this._participationCount;
  }
  setParticipationCount(count) {
    return this._participationCount=count;
  }
  increaseParticipationCount(){
    return this._participationCount++;
  }
  decreaseParticipationCount(){
    return this._participationCount--;
  }
  setShowResultTrue(){
    return this.showResult={ ok: true };
  }
  getParticipations() {
    return this._model().getItemsWhere(i => i instanceof Participation && i.getRelationship().getId() == this.getId());
  }
  hasParticipations() {
    return this.getParticipations().length;
  }
  isRecursive() {
    return this.getParticipations().length == 2 && new Set(this.getParticipations().map(p => p.getGenericET())).size == 1;
  }
  isTernary() {
    return this.getParticipations().length == 3;
  }
  isManyToMany() {
    return this.getParticipations().length == 2 && this.getParticipations().map(p => p.getMaxCardinality()).every(c => c == 'N');
  }
  isOneToMany() {
    return this.getParticipations().length == 2 && this.getParticipations().map(p => p.getMaxCardinality()).sort().join('_') == '1_N';
  }
  isOneToOne() {
    return this.getParticipations().length == 2 && this.getParticipations().map(p => p.getMaxCardinality()).every(c => c == '1');
  }
  isEntity(){
    return false;
  }
  isRelationship(){
    return true;
  }
  canAddParticipation(entity) {
    if(entity == this)
      return { result: false, error: 'Cannot connect an element with itself' };   
    return { result: true }; 
  }
  getSupportedFunctionalities() {
    let rename=true;
    if(this._typeOfInput=='result' || this._typeOfInput=='naturalJoin' || this._typeOfInput=='division'|| this._typeOfInput=='difference' || this._typeOfInput=='union' || this._typeOfInput=='intersection')
      rename=false;
    return {
      entityParticipation: true,
      attribute: false,
      moving: true,
      renaming: rename,
      translating: true
    };
  }
  getAllowedFunctionalities() {
    let entityParticipation = { ok: true };    
    return {
      entityParticipation
    };
  }

  getShowResult(){
    let showResult = { ok: true };
    if(this._participationCount >1)
    showResult = { ok: false, error: 'There could be only one result' };
    if(this._participationCount ==0)
      showResult={ ok: true };
    return showResult;
}


  
  getErrors() {
    let errors = [];
    return errors;
  }
  getWarnings() {
    return [];
  }
  __beforeDelete() {         
    this._model().getItemsWhere(i => i instanceof Participation && i.getEntityId() == this.getId()).map( p=> this._model().deleteItem(p.getId()))
    if(this.getParticipations()!=null){
      for(let participation of this.getParticipations()){
        let relationshipId = this._model().getItemById(participation.getRelationshipId());
        this._model().getItemsWhere(i => i instanceof Participation && i.getEntityId() == relationshipId.getId()).map( p=> this._model().deleteItem(p.getId()))
        this._model().deleteItem(participation.getId());
      }
    } 
  }
  setName(name) {
      this._name = name;
    return name;
  } 

  move(dx, dy) {
    this._x = Math.round(this._x + dx);
    this._y = Math.round(this._y + dy);
  }
  toERCode() {
    let draw=this.getTypeToDraw();
    let code = this.getName()=='R'? `result ${this.getName()}` : this.getTypeOfInPut()=='asemi-join' ? `operator |⋉ ${this.getName()}` : `operator ${draw} ${this.getName()}`;
    if(this.hasParticipations()) {
      code += '\ninput: ';
      code += this.getParticipations().map(p => p.toERCode()).join(', ');
      code += '\n';
    }
    return code;
  }
}