import { idealMVASubstitutesPosition } from '../utils/erModel';

export const restructureMultivalueAttribute = function(model, attributeId, unique) {
  const attribute = model.getItemById(attributeId);
  const [relationshipPosition, entityPosition] = idealMVASubstitutesPosition(attribute);
  const entity = model.addEntity(attribute.getName().toUpperCase(), entityPosition.x, entityPosition.y, true);
  const relationship = model.addRelationship(`HAS_${attribute.getName().toUpperCase()}`, relationshipPosition.x, relationshipPosition.y);
  model.addAttribute(attribute.getName(), entity.getId(), true, false, '1_1');
  model.addParticipation(attribute.getParent().getId(), null, relationship.getId(), attribute.getCardinality(), false, '');
  model.addParticipation(entity.getId(), null, relationship.getId(), unique ? '1_1' : '1_N', false, '');
  model.deleteItem(attribute.getId());
};