import Column from './column';
import Entity from './entity';
import ForeignKey from './foreignKey';
import Generalization from './generalization';
import GeneralizationChild from './generalizationChild';
import Participation from './participation';
import Relationship from './relationship';
import Table from './table';
import columnTypes from './columnTypes';
import * as restrComposite from './restructuring/compositeAttribute';
import * as restrGeneralization from './restructuring/generalization';
import * as restrMultivalued from './restructuring/multivaluedAttribute';
import * as transEntity from './translation/entity';
import * as transManyToMany from './translation/manyToManyRelationship';
import * as transOneToMany from './translation/oneToManyRelationship';
import * as transOneToOne from './translation/oneToOneRelationship';

const itemClasses = {
  Column,
  Entity,
  ForeignKey,
  Generalization,
  GeneralizationChild,
  Participation,
  Relationship,
  Table
};

export default class Model {
  constructor(uid = 1, erCode = '', sqlCode = '', items = [], uid_t = 1, uid_p = 1) {
    this.uid = uid;
    this.uid_t = uid_t; //id table
    this.uid_p = uid_p; //id predicato
    this.erCode = erCode;
    this.sqlCode = sqlCode;
    this.itemsArray = [];
    this.itemsMap = {};

    for(let item of items) {
      this.itemsArray.push(item);
      this.itemsMap[item.getId()] = item;
    }
  }
  static fromObject(obj) {
    let items = obj.itemsArray || [];
    let model = new Model(obj.uid, obj.erCode, obj.sqlCode, [], obj.uid_t, obj.uid_p);
    for(let item of items)
      model._addItem(itemClasses[item.__type].fromObject(model, item));
    return model;
  }

  getUid(){
    return this.uid;
  }
  getUidT(){
    return this.uid_t;
  }
  getUidP(){
    return this.uid_p;
  }

  _uid() {
    return this.uid++;
  }
  _uid_t() {
    return this.uid_t++;
  }
  _uid_p() {
    return this.uid_p++;
  }
  _addItem(item) {
    this.itemsArray.push(item);
    this.itemsMap[item.getId()] = item;
    return item;
  }
  _deleteItem(id) {
    const i = this.itemsArray.findIndex(i => i.getId() == id);
    if(i != -1)
      this.itemsArray.splice(i, 1);
    delete this.itemsMap[id];
  }
  getERCode() {
    return this.erCode;
  }
  getSQLCode() {
    return this.sqlCode;
  }
  getItems() {
    return this.itemsArray;
  }
  getItemById(id) {
    return id ? this.itemsMap[id] : null;
  }
  getItemWhere(filter) {
    return this.itemsArray.find(filter) || null;
  }
  getItemsWhere(filter) {
    return this.itemsArray.filter(filter);
  }
  isEmpty() {
    return this.itemsArray.length == 0;
  }
  hasErrors() {
    return this.itemsArray.some(i => i.getErrors && i.getErrors().length);
  }
  needsRestructuring() {
    return this.itemsArray.some(i => i.getSupportedFunctionalities().restructuring);
  }
  needsTranslation() {
    return this.itemsArray.some(i => i.getSupportedFunctionalities().translating);
  }
  setERCode(code) {
    this.erCode = code;
  }
  setSQLCode(code) {
    this.sqlCode = code;
  }
  addEntity(name, x, y, generatedFromMultivaluedAttribute = false) {
    const id = this._uid();
    name = name || `TABLE${id}`;
    return this._addItem(new Entity(this, id, name, x, y, generatedFromMultivaluedAttribute));
  }
  addRelationship(name, x, y) {
    const id = this._uid();
    const id_p = this._uid_p();
    name = name || `p${id_p}`;
    return this._addItem(new Relationship(this, id, name, x, y, 'unary', id_p));
  }

  addRelationshipPi(name, x, y) {
    const id = this._uid();
    const id_p = this._uid_p();
    name = name || `p${id_p}`;
    return this._addItem(new Relationship(this, id, name, x, y, 'unaryPi', id_p));
  }

  addRelationshipJoin(name, x, y) {
    //theta-join
    const id = this._uid();
    const id_p = this._uid_p();
    name = name || `p${id_p}`;
    return this._addItem(new Relationship(this, id, name, x, y, 'binary', id_p));
  }
  addRelationshipNatJoin(name, x, y) {
    const id = this._uid();
    name = '';
    return this._addItem(new Relationship(this, id, name, x, y, 'naturalJoin', ''));
  }

  addRelationshipSemiJoin(name, x, y) {
    const id = this._uid();
    const id_p = this._uid_p();
    name = name ||`p${id_p}`;
    return this._addItem(new Relationship(this, id, name, x, y, 'semi-join', id_p));
  }
  addRelationshipASemiJoin(name, x, y) {
    const id = this._uid();
    const id_p = this._uid_p();
    name = name ||`p${id_p}`;
    return this._addItem(new Relationship(this, id, name, x, y, 'asemi-join', id_p));
  }
  addRelationshipDifference(name, x, y) {
    const id = this._uid();
    name ='';
    return this._addItem(new Relationship(this, id, name, x, y, 'difference', ''));
  }
  addRelationshipDivision(name, x, y) {
    const id = this._uid();
    name='';
    return this._addItem(new Relationship(this, id, name, x, y, 'division',''));
  }

  addRelationshipIntersection(name, x, y) {
    const id = this._uid();
    name='';
    return this._addItem(new Relationship(this, id, name, x, y, 'intersection', ''));
  }
  addRelationshipUnion(name, x, y) {
    const id = this._uid();
    name='';
    return this._addItem(new Relationship(this, id, name, x, y, 'union', ''));
  }

  addRelationshipResult(name, x, y) {
    const id = this._uid();
    name = ``;
    return this._addItem(new Relationship(this, id, name, x, y, 'result', ''));
  }
  addParticipation(entityId, tableId, relationshipId, cardinality = '1_1', externalIdentifier = false, role = '', comment = '') {
    if(entityId) {
      const { result, error } = this.getItemById(relationshipId).canAddParticipation(this.getItemById(entityId));
      if(!result)
        throw new Error(error);   
    }
    return this._addItem(new Participation(this, this._uid(), entityId, tableId, relationshipId, cardinality, externalIdentifier, role, comment));
  }
  addGeneralization(parentEntityId, childEntityId) {
    const { result, error } = this.getItemById(childEntityId).canAddGeneralization(this.getItemById(parentEntityId));
    if(!result)
      throw new Error(error);
      
    let generalizationId = this.getItemWhere(i => i instanceof Generalization && i.getEntity().getId() == parentEntityId)?.getId();
    if(!generalizationId) {
      generalizationId = this._uid();
      this._addItem(new Generalization(this, generalizationId, 'p_e', parentEntityId));
    }

    return this._addItem(new GeneralizationChild(this, this._uid(), childEntityId, generalizationId));
  }
  addTable(name, x, y, generatedFromMultivaluedAttribute = false) {
    return this._addItem(new Table(this, this._uid(), name, x, y, generatedFromMultivaluedAttribute));
  }
  addColumn(name, tableId, tableIndex, identifier = false, nullable = false) {
    return this._addItem(new Column(this, this._uid(), name, columnTypes.INTEGER, identifier, false, nullable, tableId, tableIndex));
  }
  addForeignKey(columnAId, columnBId) {
    return this._addItem(new ForeignKey(this, this._uid(), columnAId, columnBId));
  }
  deleteItem(id) {
    const item = this.getItemById(id);
    if(item) {
      item.__beforeDelete?.();
      this._deleteItem(id);
      item.__afterDelete?.();
    }
  }
  restructureMultivalueAttribute(attributeId, unique = true) {
    restrMultivalued.restructureMultivalueAttribute(this, attributeId, unique);
  }
  splitCompositeAttribute(attributeId) {
    restrComposite.splitCompositeAttribute(this, attributeId);
  }
  mergeCompositeAttribute(attributeId) {
    restrComposite.mergeCompositeAttribute(this, attributeId);
  }
  substituteGeneralization(generalizationId) {
    restrGeneralization.substituteGeneralization(this, generalizationId);
  }
  translateEntity(entityId) {
    transEntity.translateEntity(this, entityId);
  }
  translateManyToManyRelationship(relationshipId) {
    transManyToMany.translateManyToManyRelationship(this, relationshipId);
  }
  translateOneToManyTypeARelationship(relationshipId) {
    transOneToMany.translateOneToManyTypeARelationship(this, relationshipId);
  }
  translateOneToManyTypeBRelationship(relationshipId, createTable = true) {
    transOneToMany.translateOneToManyTypeBRelationship(this, relationshipId, createTable);
  }
  translateOneToOneTypeARelationship(relationshipId) {
    transOneToOne.translateOneToOneTypeARelationship(this, relationshipId);
  }
  translateOneToOneTypeBRelationship(relationshipId, first = true) {
    transOneToOne.translateOneToOneTypeBRelationship(this, relationshipId, first);
  }
  translateOneToOneTypeCRelationship(relationshipId, createTable = true, first = true) {
    transOneToOne.translateOneToOneTypeCRelationship(this, relationshipId, createTable, first);
  }
  toERCode() {
    let code = '';

    const entities = this.getItemsWhere(i => i instanceof Entity);
    if(entities.length) {
      code += '/* Tables */\n';
      for(let entity of entities)
        code += entity.toERCode() + '\n';
      code += '\n';
    }

    const relationships = this.getItemsWhere(i => i instanceof Relationship);
    if(relationships.length) {
      code += '/* Operators */\n';
      for(let relationship of relationships)
        code += relationship.toERCode() + '\n';
      code += '\n';
    }

    const generalizations = this.getItemsWhere(i => i instanceof Generalization);
    if(generalizations.length)
      code += '/* Generalizations */\n';
    for(let generalization of generalizations)
      code += generalization.toERCode() + '\n';

    return code.trim();
  }
  toSQLCode() {
    let code = '';

    const tables = this.getItemsWhere(i => i instanceof Table);
    if(tables.length) {
      code += '/* Tables */\n';
      for(let table of tables)
        code += table.toSQLCode() + '\n';
      code += '\n';
    }

    return code.trim();
  }
}