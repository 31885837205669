<template>
<div>
  <transition name="fade">
    <div class="container splash" v-if="!loaded">
      <Splash />
    </div>
  </transition>
  <div class="container" v-bind:class="{ blurred : showModal }">
    <div class="panel navbar">
      <NavigationBar @input-proj="inputProj($event)" @read-name="readName($event)"/>
    </div>
    <div class="panel main">
      <MainPanel />
    </div>
  </div>
  <transition name="fade">
    <div class="modal" v-if="showModal">
      <Modal>
        <About v-if="showModal == 'about'" />
        <BugReports v-if="showModal == 'bugReports'" />
        <Feedback v-if="showModal == 'feedback'" />
        <NewProject v-if="showModal == 'newProject'" />        
        <Help v-if="showModal == 'help'" />
      </Modal>
    </div>
  </transition>
</div>
</template>

<script>
import { ref, provide } from 'vue';

import About from './components/About';
import BugReports from './components/BugReports';
import Feedback from './components/Feedback';
import MainPanel from './components/MainPanel';
import Modal from './components/Modal';
import NavigationBar from './components/NavigationBar';
import Splash from './components/Splash';
import NewProject from './components/NewProject.vue';
import Help from './components/Help';

import { mainStore } from './store/main';

export default {
  name: 'App',
  components: {
    About,
    BugReports,
    Feedback,
    MainPanel,
    Modal,
    NavigationBar,
    Splash,
    NewProject,
    Help,
  },
  setup() {
    const loaded = ref(false);
    let pN = ref('QT project');
    provide('pN', pN);
    
    const showModal = mainStore.$showModal();

    setTimeout(() => loaded.value = true, 2000);
    function inputProj(e){
      this.projectName = e.target.value;
      pN.value= e.target.value;
    }
    function readName(e){ 
      e= e[e.length-1]==')' ? e.split('(')[0] : e;
      this.projectName = e;
      pN.value= e;
    }

    return {
      loaded,
      showModal,
      inputProj,
      readName,
    };
  }
};
</script>

<style scoped>
.container, .modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.container {
  background: #fff;
}
.modal {
  z-index: 4;
  background: rgba(0, 0, 0, 0.25);
}
.blurred {
  filter: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='a' x='0' y='0' width='1' height='1'%3E%3CfeGaussianBlur stdDeviation='3' result='b'/%3E%3CfeMorphology operator='dilate' radius='3'/%3E %3CfeMerge%3E%3CfeMergeNode/%3E%3CfeMergeNode in='b'/%3E%3C/feMerge%3E%3C/filter%3E%3C/svg%3E#a");
}
.splash {
  z-index: 10;
}
.panel {
  width: 100%;
}
.navbar {
  position: relative;
  height: 44px;
  z-index: 4;
}
.main {
  height: calc(100% - 44px);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.4s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave-from {
  opacity: 1;
}
</style>