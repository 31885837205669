import { draw, resetScene, centerScene } from './draw';
import { exportToPng } from './export';
import { handle } from './listeners';

let contexts = {};

const initContext = name => {
  let context = {
    two: null,
    diagram: {
      model: null,
      step: name,
      transparent: false,
      selectedItem: null,
      selectedTool: null
    },
    transform: {
      scale: 1,
      offsetX: 0,
      offsetY: 0
    },
    canvas: {
      width: null,
      height: null
    },
    base: {
      x: null,
      y: null
    },
    mouse: {
      x: null,
      y: null
    },
    fingers: {
      oneX: null,
      oneY: null,
      twoX: null,
      twoY: null
    },
    mousePressed: false,
    panning: false,
    allowClick: true,
    supportsWheel: false
  };
  context.canvasUpdated = function(canvas) {
    canvas.innerHTML = '';
    this.canvas.width = canvas.clientWidth;
    this.canvas.height = canvas.clientHeight;
    this.base.x = canvas.getBoundingClientRect().left;
    this.base.y = this.base.y || canvas.getBoundingClientRect().top;
  
    this.two = new Two({
      width: this.canvas.width,
      height: this.canvas.height
    }).appendTo(canvas);
    
    draw(this, true);
  };
  context.exportPng = async function(canvas) {
    return await exportToPng(this, canvas);
  };
  context.modelUpdated = function(model, clear = false) {
    this.diagram.model = model;
    draw(this, clear);
  };
  context.resetScene = function() {
    resetScene(this);
  };
  context.centerScene = function() {
    centerScene(this);
  };
  context.selectedItemUpdated = function(item) {
    this.diagram.selectedItem = item;
    draw(this);
  };
  context.selectedToolUpdated = function(tool) {
    this.diagram.selectedTool = tool;
    draw(this);
  };
  context.setCallbacks = function(callbacks) {
    this.callbacks = callbacks;
  };
  context.onEvent = function(e) {
    handle(e, this);
  };
  contexts[name] = context;
};

export const getContext = name => {
  if(!contexts[name])
    initContext(name);
  return contexts[name];
};