let movingItem = false;

export const events = {
  onMove(x, y, ctx) {
    if(!ctx.diagram.selectedTool || !['entity', 'unaryInput', 'unaryInputPi', 'binaryInput', 'natural-join', 'semi-join', 'asemi-join', 'difference', 'union', 'division', 'intersection', 'result'].includes(ctx.diagram.selectedTool))
      return false;
    ctx.diagram.scene.$overlays.setPosition(x, y);
    ctx.diagram.scene.$overlays.showOverlay(ctx.diagram.selectedTool);
  },
  onClick(x, y, _, ctx) {
    if(ctx.diagram.selectedTool && !['connect', 'parent'].includes(ctx.diagram.selectedTool) && ctx.callbacks?.addItem) {
      const modelScene = ctx.diagram.scene.$model;
      ctx.callbacks.addItem(
        ((x - modelScene.translation.x) / modelScene.scale) - ctx.canvas.width / 2,
        ((y - modelScene.translation.y) / modelScene.scale) - ctx.canvas.height / 2
      );
      return false;
    }
  },
  onKey(key, ctrl, ctx) {
    if(ctx.diagram.selectedItem) {
      if(key == 46 /* delete */ && ctx.callbacks?.deleteItem)
        ctx.callbacks.deleteItem();
    }
    if(ctx.diagram.selectedTool && ctx.callbacks?.selectTool) {
      if(key == 27 /* Esc */)
        ctx.callbacks.selectTool(null);
      if(key == 69 /* e */)
        ctx.callbacks.selectTool('entity');
      if(key == 82 /* r */)
        ctx.callbacks.selectTool('relationship');
        if(key == 83 /* r */)
        ctx.callbacks.selectTool('unaryInput');
        if(key == 84 /* r */)
        ctx.callbacks.selectTool('unaryInputPi');
        if(key == 85 /* r */)
        ctx.callbacks.selectTool('binaryInput');
        if(key == 86 /* r */)
        ctx.callbacks.selectTool('natural-join');
    }
    if(ctrl && key == 90 /* z */ && ctx.callbacks?.undo)
      ctx.callbacks.undo();
    if(ctrl && key == 89 /* y */ && ctx.callbacks?.redo)
      ctx.callbacks.redo();
  },
  onPan(dx, dy, ctx) {
    if(ctx.diagram.selectedTool)
      ctx.diagram.scene.$overlays.showOverlay(null);
    else if(ctx.diagram.selectedItem) {
      movingItem = true;
      const modelScene = ctx.diagram.scene.$model;
      if(ctx.callbacks?.moveItem)
        ctx.callbacks.moveItem(dx / modelScene.scale, dy / modelScene.scale);
      return false;
    }
  },
  onPanEnd(ctx) {
    if(movingItem) {
      movingItem = false;
      if(ctx.callbacks?.endMoveItem)
        ctx.callbacks.endMoveItem();
    }
  }
};