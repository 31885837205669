import { computed } from 'vue';

import { Store } from './store';
import Model from '../lib/model';
import analytics from '../services/analytics';
import analyticsEvents from '../services/analytics/events';

export default class SchemaDesignStore extends Store {
  constructor(main) {
    super();
    this.mainStore = main;
  }
  __replace(state) {
    this.state.edited = state.edited;
    this.state.sceneResetToken = new Date().getTime();
    
    this.state.selectedItemId = null;
    this.state.oldRenamingValue = '';
    this.state.renamingValue = '';
    this.state.showRenamingInput = false;
    this.state.showDataTypeOptions = false;
    this.state.showReorderingMenu = false;
    this.state.showExportMenu = false;
    
    this.state.model = Model.fromObject(state.model || {});
    this.state.model.setSQLCode(this.state.model.toSQLCode());
  }
  data() {
    return {
      model: new Model(),
      edited: false,
      sceneResetToken: null,
      selectedItemId: null,
      oldRenamingValue: '',
      renamingValue: '',
      showRenamingInput: false,
      showDataTypeOptions: false,
      showReorderingMenu: false,
      showExportMenu: false
    };
  }
  _resetMenus() {
    let updated = false;
    if(this.state.showRenamingInput && this.state.oldRenamingValue != this.state.renamingValue)
      updated = true;

    this.state.showRenamingInput = false;
    this.state.showDataTypeOptions = false;
    this.state.showReorderingMenu = false;
    this.state.showExportMenu = false;

    if(updated) {
      this._itemsUpdated();
      const oldName = this.state.oldRenamingValue, newName = this.state.renamingValue;
      const itemType = this.state.model.getItemById(this.state.selectedItemId).constructor.name;
      analytics.event(analyticsEvents.SCHEMA_ITEM_RENAME, { itemType, oldName, newName });
    }
  }
  _itemsUpdated(pushState = true) {
    this.state.edited = true;
    this.state.model.setSQLCode(this.state.model.toSQLCode());
    if(pushState)
      this.mainStore.pushState();
  }
  $sceneResetToken() {
    return computed(() => this.state.sceneResetToken);
  }
  $highlightedCode() {
    return computed(() => Prism.highlight(this.state.model.getSQLCode(), Prism.languages.sql, 'sql'));
  }
  $selectedItem() {
    return computed(() => this.getSelectedItem());
  }
  $renamingInput() {
    return computed(() => this.state.showRenamingInput);
  }
  $dataTypeOptions() {
    return computed(() => this.state.showDataTypeOptions);
  }
  $reorderingMenu() {
    return computed(() => this.state.showReorderingMenu);
  }
  $exportMenu() {
    return computed(() => this.state.showExportMenu);
  }
  $selectedItemWarnings() {
    return computed(() => {
      const item = this.getSelectedItem();
      if(item && item.getWarnings)
        return item.getWarnings().filter(w => !w.scope || w.scope == 'schemaDesign');
      return null;
    });
  }
  $selectedItemErrors() {
    return computed(() => {
      const item = this.getSelectedItem();
      if(item && item.getErrors)
        return item.getErrors().filter(e => !e.scope || e.scope == 'schemaDesign');
      return null;
    });
  }
  getCode() {
    return this.state.model.getSQLCode();
  }
  getSelectedItem() {
    return this.state.selectedItemId ? this.state.model.getItemById(this.state.selectedItemId) : null;
  }
  getRenamingValue() {
    return this.state.renamingValue;
  }
  selectItem(id) {
    this._resetMenus();
    this.state.selectedItemId = id;
  }
  moveItem(dx, dy) {
    this._resetMenus();
    const item = this.getSelectedItem();
    if(item.getSupportedFunctionalities().moving)
      item.move(dx, dy);
  }
  finishedMovingItem() {
    const item = this.getSelectedItem();
    if(item.getSupportedFunctionalities().moving)
      this._itemsUpdated();
  }
  renameItem(name) {
    const item = this.getSelectedItem();
    if(item.getSupportedFunctionalities().renaming)
      this.state.renamingValue = item.setName(name);
    this._itemsUpdated(false);
  }
  toggleUnique() {
    const item = this.getSelectedItem();
    if(item.getSupportedFunctionalities().unique) {
      item.setUnique(!item.isUnique());
      analytics.event(analyticsEvents.SCHEMA_TOGGLE_UNIQUE_CONSTRAINT, { unique: item.isUnique() });
    }
    this._itemsUpdated();
  }
  setType(type) {
    this._resetMenus();
    const item = this.getSelectedItem();
    if(item.getSupportedFunctionalities().dataType) {
      item.setType(type);
      analytics.event(analyticsEvents.SCHEMA_ASSIGN_DATA_TYPE, { dataType: type });
    }
    this._itemsUpdated();
  }
  moveColumn(delta) {
    this._resetMenus();
    const item = this.getSelectedItem();
    if(item.getSupportedFunctionalities().reordering) {
      const currentIndex = item.getTableIndex();
      const newIndex = currentIndex + delta;
      item.getTable().getColumnByIndex(newIndex).setTableIndex(currentIndex);
      item.setTableIndex(newIndex);
      analytics.event(delta == -1 ? analyticsEvents.SCHEMA_MOVE_COLUMN_UP : analyticsEvents.SCHEMA_MOVE_COLUMN_DOWN);
    }
    this._itemsUpdated();
  }
  toggleMenu(menu) {
    if(menu == 'renaming') {
      const showRenaming = this.state.showRenamingInput;
      this._resetMenus();
      this.state.showRenamingInput = !showRenaming;
      this.state.oldRenamingValue = this.getSelectedItem().getName();
      this.state.renamingValue = this.getSelectedItem().getName();
    } else if(menu == 'dataType') {
      const showDataType = this.state.showDataTypeOptions;
      this._resetMenus();
      this.state.showDataTypeOptions = !showDataType;
    } else if(menu == 'reordering') {
      const showReordering = this.state.showReorderingMenu;
      this._resetMenus();
      this.state.showReorderingMenu = !showReordering;
    } else if(menu == 'export') {
      const showExport = this.state.showExportMenu;
      this._resetMenus();
      this.state.showExportMenu = !showExport;
    } else
      this._resetMenus();
  }
}