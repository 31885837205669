import colors from './colors';
import { drawAttribute } from './attribute';
import { drawColumn } from './column';
import { drawEntity } from './entity';
import { drawForeignKey } from './foreignKey';
import { drawGeneralizationChild } from './generalizationChild';
import { drawGeneralizationParent } from './generalizationParent';
import { drawParticipation } from './participation';
import { drawRelationship } from './relationship';
import { drawTable } from './table';
import Attribute from '../../attribute';
import Column from '../../column';
import Entity from '../../entity';
import ForeignKey from '../../foreignKey';
import Generalization from '../../generalization';
import GeneralizationChild from '../../generalizationChild';
import Participation from '../../participation';
import Relationship from '../../relationship';
import Table from '../../table';

const recipes = [
  /* ER model */
  { type: Attribute, recipe: drawAttribute },
  { type: Entity, recipe: drawEntity },
  { type: Generalization, recipe: drawGeneralizationParent },
  { type: GeneralizationChild, recipe: drawGeneralizationChild },
  { type: Participation, recipe: drawParticipation },
  { type: Relationship, recipe: drawRelationship },
  //{ type: UnaryInputPi, recipe: drawRelationship },
  /* Logical schema */
  { type: Column, recipe: drawColumn },
  { type: ForeignKey, recipe: drawForeignKey },
  { type: Table, recipe: drawTable }
];

const draw = (item, context) => {
  const drawFn = recipes.find(r => item instanceof r.type).recipe;
  drawFn(item, context);
};

export const drawModel = context => {
  const scene = context.diagram.scene;
  let _model = scene.$model;

  if(!_model) {
    let _modelGeneralizations = context.two.makeGroup();
    scene.$modelGeneralizations = _modelGeneralizations;

    let _modelParticipations = context.two.makeGroup();
    scene.$modelParticipations = _modelParticipations;

    let _modelForeignKeys = context.two.makeGroup();
    scene.$modelForeignKeys = _modelForeignKeys;

    let _modelInner = context.two.makeGroup(_modelGeneralizations, _modelParticipations, _modelForeignKeys);
    scene.$modelInner = _modelInner;
    _modelInner.translation.set(context.canvas.width / 2, context.canvas.height / 2);

    _model = context.two.makeGroup(_modelInner);
    scene.$model = _model;

    let _background = context.two.makeRectangle(context.canvas.width / 2, context.canvas.height / 2, context.canvas.width, context.canvas.height);
    _background.fill = context.diagram.transparent ? colors.WHITE : colors.TRANSPARENT;
    _background.noStroke();

    context.two.makeGroup(_background, _model);
  }

  for(let svgId in context.diagram.svgIds) {
    const id = context.diagram.svgIds[svgId];
    if(!context.diagram.model.getItemById(id)) {
      scene[`$item_${id}`].parent.remove(scene[`$item_${id}`]);
      delete scene[`$item_${id}`];
      delete context.diagram.svgIds[svgId];
    }
  }

  for(let item of context.diagram.model.getItems())
    draw(item, context);
  
  scene.$model.scale = context.transform.scale;
  scene.$model.translation.set(context.transform.offsetX, context.transform.offsetY);

  return _model;
};